.codex-input {
  box-sizing: border-box;
  margin: 0 0 2rem;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.validation-error {
  color: #ff4d4f;
  font-size: 1.4rem;
  line-height: 1.5715;
  text-align: center;
  margin-top: -1.5rem;
  margin-bottom: 1.2rem;
}
