@import "../../styles/variables";
@import "../../styles/mixins";

.Overview {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  @include respond-to-max(large) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }
}
.LeftOverview {
  float: left;
  display: flex;
  width: 50%;
  align-items: stretch;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  @include respond-to-max(large) {
    width: 100%;
  }
}
.RightOverview {
  float: left;
  display: flex;
  width: 50%;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  @include respond-to-max(large) {
    width: 95%;
  }
}

.RightRightOverview {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
}

.BottomOverview {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
}

.whiteBox {
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.12) !important;
  background-color: #fff;
  padding: 1.6rem 0 1.6rem;
  height: auto;
  border-radius: 0.5rem;
  margin-top: 2rem;
  text-align: center;
  min-height: 13.5rem;
}

.whiteBoxLeft {
  width: 90%;
}

.whiteBoxLeftButtom {
  min-height: 20rem;
}
.whiteBoxRight {
  width: 95%;
}

.whiteBoxRightRight {
  width: 49%;
}

.bottomWhiteBox {
  min-width: 95%;
  h1 {
    text-align: left;
  }
}
.withPadding {
  padding-left: 2rem;
  padding-right: 2rem;
  min-height: 22.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    text-align: left;
  }
  h2 {
    padding-bottom: 1rem;
  }
  @include respond-to(large) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  input {
    height: 5rem;
    border: 1px solid black;
  }
  label {
    vertical-align: middle;
  }
}

.check {
  color: rgb(82, 196, 26);
}

.inactive {
  color: rgb(196, 3, 3);
}

.tag {
  background-color: #fff;
}

.details {
  height: auto;
}

.progressDiv {
  display: flex;
  align-items: center; /*for vertically center*/
  justify-content: center; /*for horizontally center*/
  flex-wrap: wrap;
  flex-direction: column;
}

.meetingPreferenceTitle {
  float: left;
  margin-right: 10px;
}

.meetingPreferenceList {
  float: left;
}

.progressCircle {
  color: white;
}

.badgesSubTitles {
  float: left;
  display: flex;
  @include respond-to-max(large) {
    float: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.badges {
  margin-bottom: 1rem;
}

.timeLine {
  margin-bottom: 1rem;
}

.greyBox {
  margin-top: 1rem;
  @include respond-to-max(large) {
    margin-top: 1.5rem;
  }
  background: #aaa;
  margin-left: 10px;
  margin-right: 1rem;
  border-radius: 20%;
  width: 35px;
  height: 35px;
  line-height: 30px; /* vertical alignment */
  text-align: center;
  font-size: 16px;
  float: left;
}

.dynamiteSession {
  margin-top: 2rem;
}
.sessionDescription {
  float: inline-start;
}
.sessionTime {
  float: left;
}

.vertical {
  float: left;
  position: relative;
  display: inline-block;
  height: 4.4em;
  margin-right: 2rem;
  @include respond-to-max(large) {
    margin-right: 1rem;
  }
}

.centerContent {
  margin-top: 2rem;
}

.buttonPause {
  height: 50px;
}

.withPaddingBottom {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  h3 {
    text-align: left;
  }
  h2 {
    padding-bottom: 1rem;
  }
  @include respond-to(large) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  input {
    height: 5rem;
    border: 1px solid black;
  }
  label {
    vertical-align: middle;
  }
}

.PauseText {
  display: inline-block;
  text-align: right;
  margin-right: 12rem;
}
.PauseText2 {
  display: inline-block;
  text-align: right;
  margin-right: 2rem;
}
.PauseRangePicker {
}
.PauseTextArea {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 285px;
}
