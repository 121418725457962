.btn {
  line-height: 1.5715;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: auto;
  padding: 0.4rem 1.5rem;
  font-size: 1.4rem;
  border-radius: 2px;
  color: $white-color;
  background: $secondary-color;
  border-color: $secondary-color;
  border-radius: 32px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.btn-icon {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex: 0 0 auto;
  padding: 7px;
  overflow: visible;
  font-size: 1.3rem;
  text-align: center;
  border-color: transparent;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  color: #000;
  img {
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }
  i,
  span {
    line-height: 1.2;
    display: inline-flex;
    width: 1.2em;
    height: 1.2em;
    align-items: center;
    justify-content: center;
  }
}

.btn-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    background-color: transparent;
  }
}

.btn-action {
  width: 100%;
  height: 5rem;
  height: auto;
  border-radius: 5rem;
  font: 600 1.6rem $font-openSans;
}

.btn-orange {
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: #fff;
}

.btn_primary {
  background-color: $primary-btn-color;
  border-color: $primary-btn-color;
  color: #fff;
}

.btn_primary:hover {
  background-color: $primary-btn-color_hover;
  border-color: $primary-btn-color_hover;
  color: #fff;
}
.btn_primary:focus {
  color: $primary-btn-color_hover;
  background: $white-color;
  border-color: $primary-btn-color_hover;
}

.btn-default {
  background-color: $default-color;
  border-color: $default-color;
  color: #fff;
}

.btn-default_invert {
  background-color: #fff;
  border-color: #575757;
  color: $main-text-color;
  min-width: 85px;
}

.btn-default:hover,
.btn-default_invert:hover {
  background-color: $default_hover-color;
  color: #fff;
}

.btn-text {
  background-color: transparent;
  border-color: transparent;
  color: $main-text-color;
}

.btn[disabled] {
  color: #fff;
  background: #bdbebe;
  border-color: #bdbebe;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
