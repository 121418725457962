@import "../../styles/variables";
@import "../../styles/mixins";

.bottomNav {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100vw;
  @include respond-to-max(medium) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}
.bnTab {
  font-size: 1.5rem;
  text-align: center;
}
