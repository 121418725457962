@import "../../styles/variables";
@import "../../styles/mixins";

.loggedInContainer {
  color: white;
  text-transform: capitalize;
  text-align: center;
  height: 8.8rem;
  background-color: #f4faff;

  .mainMenuWrapper {
    margin-right: auto;
    margin-left: auto;
  }
  h1 {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 3vh;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #252733;
    text-align: start;
    margin-bottom: -3.5rem;
  }
  h4 {
    margin-bottom: -3.5rem;
  }
}

.imgContainer {
  margin: 0 auto;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  background-color: #f3f6f8;
  position: relative;
  cursor: pointer;
  top: 1.5rem;

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-border-radius: 99em;
    border: 2px solid rgb(255, 255, 255);
  }
}

.arrowDown {
  filter: invert(100);
}

.button {
  width: 100%;
}
