/*
// Variables
// ------------------------------------------------ */

$primary-color: #001529;
$secondary-color: #0096fb;
$third-color: #f4faff;
$fourth-color: #f1f1e6;

// Codex Academy Colors
$accent-color-1: #363045;
$accent-color-2: #02adfe;
$accent-color-3: #fcbe02;

$danger-color: #d32f2f;
$danger-color_hover: #c62828;

$status-color: #aacc03;
$status-color_hover: #9cb812;

$main-theme-bg: #fbf7f7;
$secondary-theme-bg: #fff;
$main-text-color: #000;
$default-color: #272727;
$default_hover-color: #1f1e1e;
$primary-btn-color: #ff9b36;
$primary-btn-color_hover: #f5902a;

$Gold: #fdbf00;
$Lime_Green: #46e96d;
$Azure_Radiance: #00adff;
$Purple_Black: #424242;
$Purple_Gray: #686573;
$Rust: #e2804b;
$Purple: #8277e3;
$Light_Gray: #d9d9d9;
$Paper: #f3f3f3;
$white-color: #fff;

$font-openSans: "Open Sans", sans-serif;
$font-segoe: "Segoe UI", "Open Sans", sans-serif;

$z-layers: (
  bottomless-pit: -9999,
  before: 0,
  default: 1,
  after: 2,
  header: 10,
  dropdown: 3000,
  overlay: 4000,
  modal: 4001,
  drama: 9999,
);

/*==== Breakpoints ====*/
$breakpoints: (
  xsmall: 320px,
  small: 375px,
  medium: 426px,
  large: 768px,
  // tablet
  xlarge: 1024px,
  // desktop
  xxl: 1400px,
  xxl-min: 1540px,
  xxxl: 1700px,
  2k: 2048px,
  2kplus: 2400px,
  xs-max: 767px,
  sm-min: 768px,
  // tablet-min
  sm-md-min: 992px,
  // tablet-min
  sm-max: 1023px,
  // tablet-max
  md-min: 1024px,
  // desktop-min
  md-max: 1199px,
  lg-min: 1200px,
  mobile-map-max: 730px,
  image-max: 1920px,
  custom-mentor: 875px,
  custom-action: 1187px,
  medium-max: 436px,
);

//font size
$default-font-size: 1.6rem;
