.ant-modal {
  width: 95%;
  @media (min-width: 768px) {
    width: 95% !important;
  }
  @media (min-width: 1024px) {
    width: 75rem !important;
  }

  @media (min-width: 1200px) {
    width: 85rem !important;
  }
}
.Btn {
  display: block;
  height: 4.8rem;
  margin: auto;
  padding: 0.4rem 7.4rem;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.170117px;
  text-transform: uppercase;
  background-color: #37b5ff !important;
  &:hover {
    background-color: #04a0fa !important;
  }
}

.formLabel {
  font: 1.2rem/1.5rem $font-segoe !important;
  margin-bottom: 1rem;
  text-align: left;
  display: block;
  text-transform: uppercase;
  color: #181b32;
  font-weight: 600 !important;
}

.ant-checkbox-group-item {
  height: 5rem !important;
  width: 25rem;
  margin-bottom: 2rem;
  margin-right: 2.5rem;
  padding-left: 3rem;
  line-height: 4.5rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  font-family: 'Mulish', sans-serif;
  font-size: 1.3rem;
  box-shadow: 4px 4px 4px rgba(225, 225, 225, 0.5);

  .ant-checkbox {
    display: none;
  }

  &.ant-checkbox-wrapper-checked {
    border: 2.5px solid #211fff;
    font-weight: bold;
  }
  .ant-badge {
    font-size: 1rem;
  }
}
