@import "../../styles/variables";
@import "../../styles/mixins";

.About {
  display: flex;
  width: 100%;

  flex-direction: row;
  justify-content: center;
}

.whiteBox {
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.12) !important;
  background-color: #fff;
  padding: 3.6rem 0 3.6rem;
  height: auto;
  border-radius: 0.5rem;
  margin-top: auto;
  margin-bottom: 4rem;

  @include respond-to-max(medium) {
    margin-bottom: 8rem;
  }
  text-align: center;
  padding-bottom: 5rem;
  width: 60%;
  @include respond-to-max(large) {
    width: 100%;
  }
}

.withPadding {
  display: flex;
  padding-left: 2.2rem;
  flex-direction: column;
  flex-wrap: wrap;
  h2 {
    margin-bottom: 4rem;
  }
  h3 {
    text-align: left;
    margin-bottom: 2rem;
  }
  @include respond-to(large) {
    padding-left: 6.2rem;
    padding-right: 5rem;
  }

  input {
    height: 5rem;
    border: 1px solid black;
  }
  label {
    vertical-align: middle;
  }
}

.check {
  color: rgb(82, 196, 26);
}

.inactive {
  color: rgb(196, 3, 3);
}

.tag {
  background-color: #fff;
}

.meetingPreferenceTitle {
  float: left;
  margin-right: 10px;
}

.meetingPreferenceList {
  float: left;
}

// .actions {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   :not(:last-child) {
//     margin-right: 2rem;
//     margin-bottom: 2rem;
//   }
//   @include respond-to-max(custom-action) {
//     :not(:last-child) {
//       margin-bottom: 2rem;
//       // margin-right: 0rem;
//     }
//   }
// }

.actions {
  margin: 10px auto;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  button {
    padding: 1em;
    font-size: 0.9em;
    margin: 0.75em;
    color: white;
    text-decoration: none;
    flex-grow: 1;
    text-align: center;
  }
}

.primary {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.secondary {
  background-color: $primary-btn-color;
  border-color: $primary-btn-color;
}

.third {
  background-color: $status-color;
  border-color: $status-color;
}

.danger {
  background-color: $danger-color;
  border-color: $danger-color;
}

.secondary:hover {
  background-color: $primary-btn-color_hover;
  border-color: $primary-btn-color_hover;
  color: #fff;
}
.secondary:focus {
  color: $primary-btn-color_hover;
  background: $white-color;
  border-color: $primary-btn-color_hover;
}

.third:hover {
  background-color: $status-color_hover;
  border-color: $status-color_hover;
  color: #fff;
}
.third:focus {
  color: $status-color_hover;
  background: $white-color;
  border-color: $status-color_hover;
}

.danger:hover {
  background-color: $danger-color_hover;
  border-color: $danger-color_hover;
  color: #fff;
}
.danger:focus {
  color: $danger-color_hover;
  background: $white-color;
  border-color: $danger-color_hover;
}

.modalStyle .ant-modal-content {
  background-color: #01579b;
}
