.u_center_text {
  text-align: center;
}

.u_margin_bottom_big {
  margin-bottom: 8rem;
}
.u_margin_bottom_small {
  margin-bottom: 3rem;
}
.u_margin_bottom_medium {
  margin-bottom: 4rem;
}
.u_margin_top_medium {
  margin-top: 4rem;
}
