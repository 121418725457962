@import "../../styles/variables";
@import "../../styles/mixins";

.logo {
  height: 27.8px;
  margin: 16px;
  img {
    width: 5rem;
  }
}

.logoText {
  position: absolute;
  width: 120px;
  left: 76px;
  top: 20px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}

.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  @include respond-to-max(medium) {
    display: none;
  }
}
